export const VERSION = '1.51.0'
export const COURSE_API_URL = `https://qa-playground-course-api.digitalhouse.com/v1/api/`
export const API_PG_URL = "https://qa-pg-api.digitalhouse.com"
export const API_PG_TOKEN="RECbGguTqe7Vb7K99EHMV1PFKxz1JYWs3gbQSqIZ"
export const GTM_ID = ''
export const BLIP_KEY = ''
export const BLIP_ENABLED = false
export const LANG_API_TOKEN = 'P0HuzQVCEQ9rGR7mKUGW02mZxeC3WgBoNXScoZE1'
export const LANG_API_URL = 'https://qa-pg-api.digitalhouse.com/lang'
export const INCOMING_DEPLOY_INTERVAL = 1000 * 60
export const NOTIFICATION_API_URL = 'https://qa-playground-notification-api.digitalhouse.com/v0/api'
export const IDLE_TIME_OUT = 1000 * 60 * 60
export const KEEP_SESSION_TIME = 1000 * 60 * 60 * 24 * 30
export const EXPIRE_SESSION_TIMEOUT = 1000 * 60 * 2
export const TTL_COURSE_CACHE = 5
export const FILES_SIZE_LIMIT_MB = 50
export const MICROFRONTENDS_URL = 'https://d33shs32eni62o.cloudfront.net'
export const ASSETS_URL='https://assets.digitalhouse.com/pg/qa'
export const IGNORE_REDUX_LOGGER = false
export const TENANT_API_URL = 'https://qa-pg-api.digitalhouse.com/tenant'
export const SGE_FLAG = 'v2'
export const VAULTY_WRITER_URL = 'https://vaulty.digitalhouse.com'
export const VAULTY_READER_URL = 'https://assets.digitalhouse.com'
export const VAULTY_API_KEY = 'VTLQ2S3uqSGQ9W1tBJh70GVVratqUt5EAFrbmbZy'
export const VAULTY_PATH = 'pg/qa'
export const DASHBOARD_API_URL = 'https://qa-pg-api.digitalhouse.com/dashboard'
export const SENTRY_DSN = 'https://fde1b87defb84eb0bd4fbc4ea82d0bb1@o277372.ingest.sentry.io/1551478'
export const STAGE = 'qa'
export const PROGRESS_API_URL = 'https://qa-pg-api.digitalhouse.com/progress'
export const PROGRESS_API_TOKEN = 'f95FzgSaZQ2zhPaeu4Unt43SL12ZDU94snZ4oxRj'
export const EGG_SCRIPT_URL = 'https://egg-widget-script.s3-accelerate.amazonaws.com/dev/dh-script-dev.js'
export const HEAP_ID = '1170951781'
export const DH_WEB_URL = 'https://www.digitalhouse.com'
export const SEGMENT_WRITE_KEY = 'UfH2ZphjCcmRf1MZIJ4Lct4639jtSd64'
export const SEGMENT_URL = 'https://qa-cdnsegio.digitalhouse.com'
export const ASSESSMENT_API_URL = 'https://qa-pg-api.digitalhouse.com/assessment/v1'
export const ASSESSMENT_API_TOKEN = 'roZAdukGop5wrV39NEodS8vJIvq7WAzD59GI35Ta'
export const DASHBOARD_API_TOKEN = 'ddpZEpbgkA1On379BPcRI2XmeuCF730B5lYYoaxD'
export const USER_MANUAL_URL = 'https://digitalhouse.notion.site/Libro-de-notas-3129519e8dc14f25a91a6185e23d3ab6'
export const CHANGE_PAYMENT_URL = 'https://qa-growth-api.digitalhouse.com/payment-methods'
export const CHANGE_PAYMENT_TOKEN = 'ezCI6FF50KaPnEBCvd5ru8Z8x974H92j2LlFlpNz'
export const DLOCAL_AR_KEY="b778e022-e7dd-44b7-a847-c18062973e9e"
export const DLOCAL_CO_KEY="ed1e5218-4182-4ea2-a7c3-84a46799b061"
export const DLOCAL_XX_KEY="0a968d66-6378-42f9-bacc-5b95a51a6386"
export const DLOCAL_SANDBOX_MODE=true
export const DLOCAL_UY_KEY="6a003f31-770a-4838-a897-a9cfee9d323c"
export const DLOCAL_BR_KEY="0c27f2ce-8fb9-45e8-9cd9-06140c11c078"
export const NOTION_API_URL = 'https://qa-pg-api.digitalhouse.com'
export const NOTION_API_KEY = '5b0F82H5svqJKfziSMDr9luBa8fCF6h46KWbRX24'
export const COOKIE_SESSION_KEY = 'dh-pg-session'
export const PROFILE_URL = 'https://qa-playground.digitalhouse.com/profile'
export const INSIGHTS_URL = 'https://qa-playground.digitalhouse.com/insights'
export const COMMUNITY_URL = 'https://qa-community.digitalhouse.com/discord-redirect'
export const GRAPH_API_URL = 'https://yvktswuj35expl7obcz2xneayi.appsync-api.us-east-1.amazonaws.com/graphql'
